import React from 'react';
import CreateTrip from '../components/trips/CreateTrip';

const CreateTripPage = () => {
    return (
        <>
            <CreateTrip />
        </>
    );
};

export default CreateTripPage;
