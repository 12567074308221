import axios from 'axios';
import type { Trip } from '../types/Trip';
import type { Expense } from '../types/Expense';
import { Settlement } from '../types/Settlement';


export const fetchTripDetails = async (tripId: string, token: string, API_BASE_URL: string) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/api/trips/${tripId}`, {
            headers: { Authorization: `Bearer ${token}` },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to fetch trip details');
    }
};

export const updateTripDetails = async (tripId: string, updatedTripData: any, token: string, API_BASE_URL: string) => {
    await axios.put(`${API_BASE_URL}/api/trips/${tripId}`, updatedTripData, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

export const deleteTrip = async (tripId: string, token: string, API_BASE_URL: string) => {
    await axios.delete(`${API_BASE_URL}/api/trips/${tripId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

export const generateJoinLink = async (tripId: string, token: string, API_BASE_URL: string) => {
    const response = await axios.post(`${API_BASE_URL}/api/trips/${tripId}/generate-join-link`, {}, {
        headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.joinLink;
};

export const fetchSettlements = async (expenses: Expense[], trip: Trip, settlementHistory: Settlement[], API_BASE_URL: string, token: string): Promise<any[]> => {
    console.log(expenses);

    try {
        const response = await axios.post(
            `${API_BASE_URL}/api/calculate-settlements`,
            {
                expenses: expenses,
                participants: trip.participants,
                settlementHistory: settlementHistory
            },
            {
                headers: { Authorization: `Bearer ${token}` },
            }
        );

        console.log(response.data);

        return response.data;
    } catch (error) {
        console.error('Error fetching settlements:', error);
        throw new Error('Failed to fetch settlements');
    }
};
